.render {
  display: relative;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom, rgba(12, 11, 11, 0.973), rgba(0, 0, 0, 0.945));
  -webkit-animation: gradient 15s linear infinite;
          animation: gradient 15s linear infinite;
  background-size: 100%;
  background-position: top;
  font-family: "EB Garamond";
  font-weight: 100;
  overflow: hidden;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0% 0% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 0% 0% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}/*# sourceMappingURL=render.css.map */