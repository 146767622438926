@import url("https://fonts.googleapis.com/css?family=EB+Garamond");

.Global {
  height: 100vh;
  width: 100vw;
}

.Main {
  display: relative;
  width: 100vw;
  background-image: url(BG-1.png);
  animation: moving 30s linear infinite;
  font-family: 'EB Garamond';
  font-weight: 100;
  overflow: hidden;
  

    h1 {
      text-transform: uppercase;
      letter-spacing: .6rem;
    }
}

.mobile-page {
  height: 100vh;
  width: 100vw;
  color: #ffffff;
  position: absolute;
  z-index: 100;
  background-image: url(BG-1.png);
  animation: moving 30s linear infinite;

  h6{
     text-align: center;
     margin-top: 30vh;
  }
}

.address-btn{
  color: #f2f2f2;
  border-radius: 10%;
  font-size: large;
  text-transform: uppercase;
  width: 90%;
  margin: 10px auto;
}


  @keyframes button-hover {
      0%{ 
          color: #ffffff;
          border: 1px solid rgb(255, 255, 255);

      }
      50% {
        color: #ffffff9c;
        border: 2px solid rgb(255, 0, 0);
      }
      100% {
        color: white;
        border: 1px solid rgb(255, 255, 255);
      }
  }

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes moving {
	0% {
		background-position: 0% 0%;
	}

	100% {
		background-position: 100% 0%;
	}
}


.front {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  

    .card-text {
      height: 100%;
      width: 100%;
      font-size: 2rem;
      margin: auto 0;
      color: white;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      -webkit-animation-name: bob-on-hover-float, bob-on-hover;
      animation-name: bob-on-hover-float, bob-on-hover;
      -webkit-animation-duration: .3s, 1.5s;
      animation-duration: .3s, 1.5s;
      -webkit-animation-delay: 0s, .3s;
      animation-delay: 0s, .3s;
      -webkit-animation-timing-function: ease-out, ease-in-out;
      animation-timing-function: ease-out, ease-in-out;
      -webkit-animation-iteration-count: 1, infinite;
      animation-iteration-count: 1, infinite;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-direction: normal, alternate;
      animation-direction: normal, alternate;

      .innerCard{
        position: relative;
        text-align: center;
        width: 100%;
        height: 55%;
        padding: 7%;

        a {
          text-decoration: underline;
          color: #f2f2f2;
        }
        }
          hr {
            width: 100%;
          }

       }
      
        .footnote {
          font-style: italic;
          font-size: 1.2rem;
        }
    }



.img:hover {
  transition: 3s ease-in-out;
  transform: left 50px;
  cursor: pointer;
}

.card {
  height: 100vh;
  width: 100vw;

  img {
    height: 95vh;
    position: relative;
    margin-top: 5vh;
    transition: 3s;
      }
  }

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* Style the links inside the navigation bar */

.navbar{
  position: fixed;
  display: flex;
  top: 0;
  height: 5vh;
  width: 100vw;
  text-transform: uppercase;
  letter-spacing: .2rem;
  background-color: rgba(0, 0, 0, 0.767);
  text-align: right;
  justify-content: flex-end;
  z-index: 1;
  padding-right: 100px;
  backdrop-filter: blur(4px);
  animation: moving 30s linear infinite;
  
}

.navbar a {
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar a:hover {
  background-color: rgba($color: #ffffff, $alpha: 1);
  color: black;
  border-radius: 10px;
  padding: 12px 16px;
}


.wallet-btn {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: white;
  
}



@-webkit-keyframes bob-on-hover {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes bob-on-hover {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes bob-on-hover-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes bob-on-hover-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}


.bob-on-hover:hover, .bob-on-hover:focus, .bob-on-hover:active {
  -webkit-animation-name: bob-on-hover-float, bob-on-hover;
  animation-name: bob-on-hover-float, bob-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}


@media only screen and (max-width: 600px) {
  
  
  .img{
    display: none;
  }

  .navbar {
    padding-right: 0;
    text-align: center;
    justify-content: center;
    align-content: center;
    font-size: xx-small;
  }

  .Main {
    position: relative;
    top: 10px;
    display: block;
    height: 900px;
    width: 500px;
    font-size: 1px;
  }


  .card{
    display:none;
  }

  .front {
    min-height: 100%;
    display: flexbox;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    
  
      .card-text {
        height: 80%;
        width: 80%;
        font-size: 1.2rem;
        color: white;
        display: relative;
        justify-content: center;
        align-items: center;
        -webkit-animation-name: bob-on-hover-float, bob-on-hover;
        animation-name: bob-on-hover-float, bob-on-hover;
        -webkit-animation-duration: .3s, 1.5s;
        animation-duration: .3s, 1.5s;
        -webkit-animation-delay: 0s, .3s;
        animation-delay: 0s, .3s;
        -webkit-animation-timing-function: ease-out, ease-in-out;
        animation-timing-function: ease-out, ease-in-out;
        -webkit-animation-iteration-count: 1, infinite;
        animation-iteration-count: 1, infinite;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-direction: normal, alternate;
        animation-direction: normal, alternate;
  
        .innerCard{
          position: relative;
          text-align: center;
          width: 100%;
          height: 100%;
          }
            hr {
              width: 70%;
            }
  
         }
        
          .footnote {
            font-style: italic;
            font-size: 1.2rem;
          }
      }
  
  
}
