@import url("https://fonts.googleapis.com/css?family=EB+Garamond");
* {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
* ::-webkit-scrollbar {
  width: 10px;
  z-index: 100;
}
* ::-webkit-scrollbar-track {
  background: #202020;
  z-index: 100;
}
* ::-webkit-scrollbar-thumb {
  background: rgb(61, 61, 61);
  z-index: 100;
}
* ::-webkit-scrollbar-thumb:hover {
  background: #555;
  z-index: 100;
}

.index {
  background-color: rgb(0, 0, 0);
  height: auto;
  width: auto;
  overflow-x: hidden;
  /* width */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "EB Garamond";
}

@media only screen and (max-width: 600px) {
  .index {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin: 0;
    overflow-x: scroll;
  }
}/*# sourceMappingURL=index.css.map */