.codex {
  height: 100vh;
  background-image: url(BG-1.png);
  -webkit-animation: moving 30s linear infinite;
  animation: moving 30s linear infinite;
  justify-content: center;
  background-size: 400% 400%;
  display: flex;
  align-items: center;
}
.codex .main-codex {
  position: relative;
  height: 80vh;
  width: 70vw;
  border-radius: 3%;
  margin: 0 auto;
  background-color: rgba(15, 15, 15, 0.158);
  box-shadow: 10px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-backdrop-filter: invert(0.1);
          backdrop-filter: invert(0.1);
}
.codex .main-codex .nft {
  height: 100%;
  width: 50%;
  box-shadow: 10px;
  border-radius: 3%;
  position: absolute;
  left: 0;
  background-size: cover;
  background-position: center;
  box-shadow: 12px -2px 10px rgba(0, 0, 0, 0.404);
}
.codex .main-codex .metadata {
  position: absolute;
  right: 0;
  width: 48%;
  height: 100%;
  color: rgba(255, 255, 255, 0.87);
  top: 10%;
}
.codex .main-codex .metadata hr {
  width: 90%;
  margin-right: 30%;
  left: 0;
}
.codex .main-codex .metadata h1 {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: x-large;
  margin-bottom: 5%;
  margin-top: 8%;
}
.codex .main-codex .metadata p {
  font-size: large;
  width: 90%;
}
.codex .main-codex .metadata .attributes {
  display: flex;
  margin-top: 5%;
  align-self: center;
}
.codex .main-codex .metadata .attributes :hover {
  filter: invert(1);
  scale: 1.2;
}
.codex .main-codex .metadata .attributes .attr-box {
  padding: 10px;
  border-radius: 5%;
  background-color: grey;
  background-color: rgba(15, 15, 15, 0.63);
  margin-left: 2px;
}

@-webkit-keyframes moving {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
@keyframes moving {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}/*# sourceMappingURL=codex.css.map */