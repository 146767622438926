.news-con {
  background-color: rgba(77, 72, 72, 0);
  -webkit-backdrop-filter: blur(200px);
          backdrop-filter: blur(200px);
  margin: 0 auto;
  width: 20%;
  height: 60%;
  padding-bottom: 10%;
}
.news-con .image-container {
  width: 100%;
  height: 25vh;
  background-size: cover;
  border-radius: 2%;
  background-position: center;
}
.news-con .news-txt-container {
  color: rgba(255, 255, 255, 0.87);
}

.mint-btn {
  background-color: #dddddd;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 9px 22px;
  font-weight: bold;
  color: #030303;
  border-radius: 10px;
  font-size: large;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: #dddddd;
}
.mint-btn a:link {
  color: #030303;
}

.mint-btn:hover {
  cursor: pointer;
}

.button {
  scale: 1.2;
  position: absolute;
  left: 2%;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.main-ui {
  background-image: linear-gradient(to left, rgba(20, 20, 20, 0.322), rgba(255, 0, 0, 0)), url(/public/assets/card-5.png);
  position: absolute;
  border-radius: 10px;
  background-size: cover;
  height: 95vh;
  width: 40vw;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  bottom: 0;
  left: 0;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.tag {
  color: rgb(238, 237, 237);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.623), rgba(255, 0, 0, 0));
  text-transform: uppercase;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 10px;
}

.stats {
  position: absolute;
  right: 10%;
  top: 25vh;
  color: aliceblue;
}

.storage {
  display: flex;
  flex-direction: column;
  margin: 0% 5% 5% 25%;
  font-size: small;
  scale: 0.8;
}
.storage .main-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: rgba(221, 221, 221, 0.5215686275) dashed 4px;
}
.storage .main-data .metadata {
  background-color: #030303;
  height: 3vh;
  width: 50%;
  text-align: center;
  border-radius: 10px;
  border: rgba(221, 221, 221, 0.6980392157) solid 2px;
  padding: 10px 15px;
  position: absolute;
  right: 0;
}
.storage .main-data .metadata h1 {
  margin: auto 0;
}

.storage-item {
  background-color: rgba(161, 188, 247, 0.15);
  width: 23%;
  margin: 1%;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.storage-item img {
  margin: auto 0;
}
.storage-item h2 {
  background-color: rgba(3, 3, 3, 0.5215686275);
  color: antiquewhite;
  text-align: center;
}

.storage-item :hover {
  scale: 0.5;
}

.news-carousel {
  background-position: bottom;
  background-size: contain;
}

.attributes2 {
  background-color: rgba(3, 3, 3, 0.3019607843);
  height: 10vh;
  font-size: large;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
}
.attributes2 .attr-box2 {
  margin: 2%;
  width: 46%;
}
.attributes2 .attr-box2 img {
  height: 10px;
  margin: auto 0;
  padding-right: 6px;
  filter: grayscale(100%);
  filter: invert(1);
}

.lore-armory {
  font-size: larger;
  padding: 10%;
  text-align: center;
}

.cycle {
  display: flex;
  flex-direction: row;
}
.cycle button {
  padding: 0% 10%;
  margin: 0 auto;
  background-color: rgba(224, 202, 202, 0.4039215686);
  border-radius: 10px;
  color: rgb(250, 248, 248);
}

button:hover {
  background-color: rgba(163, 163, 163, 0.9921568627);
  cursor: pointer;
}/*# sourceMappingURL=components.css.map */